var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"commission-reports-view"}},[(!_vm.showLoader)?_c('div',[_c('m-page-title',{attrs:{"title":"Commission Reports"}}),_c('div',{staticClass:"row my-4 align-items-start"},[_c('div',{staticClass:"col-lg-3 col-md-12"},[_c('b-form-group',{staticClass:"field-label-form-group",attrs:{"id":"select-sales-rep","label":"Select Sales Rep","label-for":"sales-rep"}},[_c('b-form-select',{attrs:{"id":"sales-rep","options":_vm.salesRepList,"value-field":"user_id","text-field":"full_name","disabled":_vm.roles.includes('SLS_REP')},on:{"change":function () { _vm.fileUrl = ''; _vm.pdfComponentReady = false }},model:{value:(_vm.searchSalesRep),callback:function ($$v) {_vm.searchSalesRep=$$v},expression:"searchSalesRep"}})],1)],1),_c('div',{staticClass:"col-lg-4 col-md-12"},[_c('m-text-input',{attrs:{"id":"from-date","label":"From","type":"date","value":_vm.searchFromDate,"alternative":false,"group":false,"name":"From Date"},on:{"update-value":function (input) {
              _vm.searchFromDate = input
              _vm.fileUrl = ''
              _vm.pdfComponentReady = false
            }}})],1),_c('div',{staticClass:"col-lg-4 col-md-12"},[_c('m-text-input',{attrs:{"id":"to-date","label":"To","type":"date","value":_vm.searchToDate,"alternative":false,"group":false,"name":"To Date"},on:{"update-value":function (input) {
              _vm.searchToDate = input
              _vm.fileUrl = ''
              _vm.pdfComponentReady = false
            }}})],1)]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"success","size":"sm","disabled":_vm.disableButton},on:{"click":function($event){return _vm.getGrossProfitOnSalesDetail()}}},[_vm._v("Gross Profit on Sales")]),_c('b-button',{staticClass:"mr-2",attrs:{"variant":"success","size":"sm","disabled":_vm.disableButton},on:{"click":function($event){return _vm.getNewClientDetail()}}},[_vm._v("New Customer")]),_c('b-button',{staticClass:"mr-2",attrs:{"variant":"success","size":"sm","disabled":_vm.disableButton},on:{"click":function($event){return _vm.getNewRentalDetail()}}},[_vm._v("New Rental Depot")]),_c('b-button',{staticClass:"mr-2",attrs:{"variant":"info","size":"sm"},on:{"click":function($event){return _vm.clearReport()}}},[_vm._v("Clear")])],1)])],1):_vm._e(),_c('pdf-viewer',{attrs:{"file-url":_vm.fileUrl,"file-name":_vm.fileName},on:{"pdf-ready":function($event){return _vm.watchPdfComponent($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }