<template>
  <div v-if="!showLoader">
    <m-page-title title="Sales Targets" />

    <b-row class="my-4 align-items-center">
      <b-colxx
        md="3"
        sm="12">
        <b-form-group
          id="select-fiscal-year"
          label="Fiscal Year"
          label-for="fiscal-year"
          class="field-label-form-group mt-2">
          <b-form-select
            id="fiscal-year"
            v-model="searchFiscalYear"
            required
            :options="fiscalYear"
            @change="updateSelection()"
          >
          </b-form-select>
        </b-form-group>
      </b-colxx>
      <b-colxx
        md="3"
        sm="12">
        <b-form-group
          id="supplier"
          label="Supplier"
          label-for="supplier-select"
          class="field-label-form-group mt-2">
          <b-form-select
            id="supplier-select"
            v-model="searchSupplier"
            :options="supplierList"
            required
            value-field="erp_supplier_id"
            text-field="name"
            @change="updateSelection()"  
          ></b-form-select>
        </b-form-group>
      </b-colxx>
      <b-colxx
        md="6"
        sm="12">
        <b-button
          variant="success"
          class="mr-1"
          :disabled="disableSearchButton"
          @click="getTargetData()"
          >View</b-button
        >
        <b-button
          variant="warning"
          class="mr-1"
          @click="clearResults()"
          >Clear</b-button
        >
        <b-button
          variant="info"
          class="float-right"
          :disabled="disableGenerateReportButton"
          @click="generateReport()"
          >Generate Report</b-button
        >
      </b-colxx>
    </b-row>

    <div v-if="showResults">
      <b-row class="bg-primary p-4">
        <b-colxx
          sm="12"
          md="12">
          <div class="text-white h3">Supplier: {{ salesTargetData.supplier_name }}</div>
          <div class="text-white h3">
            Sales Data Compiled: {{ salesTargetData.previous_fiscal_start }} to
            {{ salesTargetData.previous_fiscal_end }}
          </div>
        </b-colxx>
      </b-row>
      <b-alert
        v-model="saveSalesTargetAlert"
        class="mt-4"
        :variant="saveSalesTargetAlertVariant"
        dismissible>
        {{ saveSalesTargetAlertMessage }}
      </b-alert>
      <b-row class="mt-4">
        <b-colxx
          sm="12"
          md="12">
          <b-table
            id="sales-target-table"
            ref="__salesTargetTable"
            head-variant="dark"
            responsive
            :items="salesTargetData.sales_rep_data"
            :fields="sales_target_list_fields"
            :per-page="0">
            <template #cell(total_sales)="row"> $ {{ row.item.total_sales.toFixed(2) }} </template>
            <template #cell(previous_year_actual_sales)="row">
              $ {{ parseFloat(row.item.previous_year_actual_sales).toFixed(2) }}
            </template>
            <template #cell(adjusted_previous_year_actual_sales)="row">
              $
              <input
                v-model="row.item.adjusted_previous_year_actual_sales"
                type="number"
                @blur="updateAdjustedPYSales(row.item, row.index)" />
            </template>
            <template #cell(expected_sales_growth_percent)="row">
              <input
                v-model="row.item.expected_sales_growth_percent"
                type="number"
                @blur="updateTargetCalcPercent(row.item, row.index)" />
              %
            </template>
            <template #cell(expected_sales_growth_dollar)="row">
              $ {{ parseFloat(row.item.expected_sales_growth_dollar).toFixed(2) }}
            </template>
            <template #cell(current_year_target_sales)="row"> $ {{ row.item.current_year_target_sales }} </template>
            <template #bottom-row>
              <td></td>
              <td class="strong-footer">Totals</td>
              <td class="text-right strong-footer">
                $ {{ parseFloat(salesTargetData.total_product_line_sales).toFixed(2) }}
              </td>
              <td class="text-right strong-footer">
                $ {{ parseFloat(salesTargetData.total_adjusted_previous_year_actual_sales).toFixed(2) }}
              </td>
              <td class="text-right strong-footer">
                {{ parseFloat(salesTargetData.total_expected_sales_growth_percent).toFixed(2) }} %
              </td>
              <td class="text-right strong-footer">
                $ {{ parseFloat(salesTargetData.total_expected_sales_growth_dollar).toFixed(2) }}
              </td>
              <td class="text-right strong-footer">
                $ {{ parseFloat(salesTargetData.total_current_year_target_sales).toFixed(2) }}
              </td>
            </template>
          </b-table>
        </b-colxx>
      </b-row>
      <b-row class="mt-4">
        <b-colxx
          sm="12"
          md="12">
          <b-button
            variant="info"
            size="xl"
            class="float-right"
            @click="saveSalesTarget()"
            >Save</b-button
          >
        </b-colxx>
      </b-row>
    </div>

    <pdf-viewer :file-url="fileUrl" file-name="Sales Targets.pdf" @pdf-ready="watchPdfComponent($event)" />
  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"
  import { baseDomain } from "@/constants/common"
  import { fiscalYear } from "@/constants/fiscal_year"
  import PdfViewer from "@/components/PdfViewer.vue"

  export default {
    name: "SalesTargets",
    components: {
      PdfViewer,
    },

    data() {
      return {
        fiscalYear,
        searchFiscalYear: null,
        searchSupplier: null,

        supplierList: [],
        salesTargetData: [],
        sales_target_list_fields: [
          {
            key: "action",
            label: "",
            sortable: false,
          },
          {
            key: "sales_rep_name",
            label: "Sales Rep",
            sortable: false,
          },
          {
            key: "previous_year_actual_sales",
            label: "Previous Year Actual Sales",
            sortable: false,
            tdClass: "text-right",
            thClass: "text-right",
          },
          {
            key: "adjusted_previous_year_actual_sales",
            label: "Adjusted Actual Sales",
            sortable: false,
            tdClass: "text-right",
            thClass: "text-right",
          },
          {
            key: "expected_sales_growth_percent",
            label: "Percent Growth",
            sortable: false,
            tdClass: "text-right",
            thClass: "text-right",
          },
          {
            key: "expected_sales_growth_dollar",
            label: "Dollar Growth",
            sortable: false,
            tdClass: "text-right",
            thClass: "text-right",
          },
          {
            key: "current_year_target_sales",
            label: "Current Year Target Sales",
            sortable: false,
            tdClass: "text-right",
            thClass: "text-right",
          },
        ],

        editRow: null,
        editRowIndex: null,
        showResults: false,

        saveSalesTargetAlert: false,
        saveSalesTargetAlertVariant: "",
        saveSalesTargetAlertMessage: "",

        fileUrl: "",
        pdfComponentReady: false,
      }
    },

    computed: {
      ...mapGetters("auth", ["user"]),
      ...mapGetters("common", ["showLoader"]),

      disableSearchButton() {
        if (this.searchFiscalYear && this.searchSupplier) {
          return false
        } else {
          return true
        }
      },

      disableGenerateReportButton() {
        if (this.searchFiscalYear) {
          return false
        } else {
          return true
        }
      },
    },

    created() {},

    async mounted() {
      document.title = "Sales Targets"
      this.$store.dispatch("common/showLoader", true)
      await django_session
        .get("erp/product/supplier/list/", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => (this.supplierList = response.data.filter((item) => item.is_inactive_yn == false)))
        .catch((error) => {
          console.log(error.response)
        })
      this.$store.dispatch("common/showLoader", false)
    },

    methods: {
      clearResults() {
        this.searchFiscalYear = null
        this.searchSupplier = null
        this.salesTargetData = []
        this.showResults = false
        this.fileUrl = ""
        this.pdfComponentReady = false
      },

      updateSelection() {
        this.salesTargetData = []
        this.showResults = false
        this.fileUrl = ""
        this.pdfComponentReady = false
      },

      async getTargetData() {
        this.$store.dispatch("common/showLoader", true)
        this.fileUrl = ""
        this.pdfComponentReady = false
        this.showResults = false
        await django_session
          .get("erp/finance/sales-target/list/", {
            params: {
              fiscal_year: this.searchFiscalYear,
              supplier: this.searchSupplier,
            },
          })
          .then((response) => {
            // console.log(response.data)
            this.salesTargetData = response.data
          })
          .catch((error) => {
            console.log(error.response)
          })
        this.showResults = true
        this.$store.dispatch("common/showLoader", false)
      },

      updateAdjustedPYSales(item, index) {
        let values = item
        let idx = index
        let previousYearAdjustedSales = parseFloat(values.adjusted_previous_year_actual_sales)
        this.salesTargetData.sales_rep_data[idx].adjusted_previous_year_actual_sales =
          parseFloat(previousYearAdjustedSales).toFixed(2)

        let totalPreviousYearAdjustedSales = 0
        for (var l = 0; l < this.salesTargetData.sales_rep_data.length; l++) {
          // let totalPreviousYearAdjustedSales = 0
          let previousYearAdjustedSales = parseFloat(
            this.salesTargetData.sales_rep_data[l].adjusted_previous_year_actual_sales
          )
          totalPreviousYearAdjustedSales = previousYearAdjustedSales + parseFloat(totalPreviousYearAdjustedSales)
          this.salesTargetData.total_adjusted_previous_year_actual_sales =
            parseFloat(totalPreviousYearAdjustedSales).toFixed(2)
          this.updateTargetCalcPercent(item, index)
        }
      },

      updateTargetCalcPercent(item, index) {
        console.log("Percent", item)
        let expectedSalesGrowthPercent = parseFloat(item.expected_sales_growth_percent)
        let calculateDollar =
          parseFloat(expectedSalesGrowthPercent / 100) * parseFloat(item.adjusted_previous_year_actual_sales)
        let currentYearTargetNew = calculateDollar + parseFloat(item.adjusted_previous_year_actual_sales)
        this.salesTargetData.sales_rep_data[index].expected_sales_growth_dollar = parseFloat(calculateDollar).toFixed(2)
        this.salesTargetData.sales_rep_data[index].current_year_target_sales =
          parseFloat(currentYearTargetNew).toFixed(2)
        this.salesTargetData.total_expected_sales_growth_percent = parseFloat(expectedSalesGrowthPercent).toFixed(2)

        let totalExpectedSalesGrowthDollar = 0
        let totalCurrentYearTargetSales = 0
        let totalExpectedSalesGrowthPercent = 0
        for (var m = 0; m < this.salesTargetData.sales_rep_data.length; m++) {
          let expectedSalesGrowthDollar = parseFloat(
            this.salesTargetData.sales_rep_data[m].expected_sales_growth_dollar
          )
          let currentYearTargetSales = parseFloat(this.salesTargetData.sales_rep_data[m].current_year_target_sales)
          let expectedSalesGrowthPercent = parseFloat(
            this.salesTargetData.sales_rep_data[m].expected_sales_growth_percent
          )
          totalExpectedSalesGrowthDollar = expectedSalesGrowthDollar + parseFloat(totalExpectedSalesGrowthDollar)
          totalCurrentYearTargetSales = currentYearTargetSales + parseFloat(totalCurrentYearTargetSales)
          // totalExpectedSalesGrowthPercent = expectedSalesGrowthPercent + parseFloat(totalExpectedSalesGrowthPercent)
          totalExpectedSalesGrowthPercent = parseFloat(
            (totalExpectedSalesGrowthDollar / this.salesTargetData.total_adjusted_previous_year_actual_sales) * 100
          ).toFixed(2)
          this.salesTargetData.total_expected_sales_growth_dollar =
            parseFloat(totalExpectedSalesGrowthDollar).toFixed(2)
          this.salesTargetData.total_current_year_target_sales = parseFloat(totalCurrentYearTargetSales).toFixed(2)
          this.salesTargetData.total_expected_sales_growth_percent = parseFloat(
            totalExpectedSalesGrowthPercent
          ).toFixed(2)
        }
      },

      saveSalesTarget() {
        console.log("Save Sales Target")
        django_session
          .post("erp/finance/sales-target/save/", this.salesTargetData)
          .then((response) => {
            console.log(response.data)
            this.saveSalesTargetAlertVariant = "success"
            this.saveSalesTargetAlertMessage = response.data.message
            this.saveSalesTargetAlert = true
          })
          .catch((error) => {
            console.log(error.response)
            this.saveSalesTargetAlertVariant = "danger"
            this.saveSalesTargetAlertMessage = error.response.data.message
            this.saveSalesTargetAlert = true
          })
      },

      async generateReport() {
        this.$store.dispatch("common/showLoader", true)
        this.showResults = false
        this.fileUrl = baseDomain + "erp/finance/companySalesTargets?fiscalYear=" + this.searchFiscalYear + "&token=" + this.user.gem_token
        this.$store.dispatch("common/showLoader", false)
        // console.log("fileUrl", this.fileUrl)
      },

      watchPdfComponent(event) {
        console.log("pdfComponentReady", event)
        this.pdfComponentReady = event
      },
    },
  }
</script>

<style lang="scss" scoped>
  .strong-footer {
    font-weight: 700;
    font-size: 1.2rem;
  }

  input {
    width: 100px;
    text-align: right;
    padding-right: 0;
  }
</style>
