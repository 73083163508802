<template>
  <div id="commission-reports-view">
    <div v-if="!showLoader">
      <m-page-title title="Commission Reports" />

      <div class="row my-4 align-items-start">
        <div class="col-lg-3 col-md-12">
          <b-form-group
            id="select-sales-rep"
            label="Select Sales Rep"
            label-for="sales-rep"
            class="field-label-form-group">
            <b-form-select
              id="sales-rep"
              v-model="searchSalesRep"
              :options="salesRepList"
              value-field="user_id"
              text-field="full_name"
              :disabled="roles.includes('SLS_REP')"
              @change="() => { fileUrl = ''; pdfComponentReady = false }"
            >
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-lg-4 col-md-12">
          <m-text-input
            id="from-date"
            label="From"
            type="date"
            :value="searchFromDate"
            :alternative="false"
            :group="false"
            name="From Date"
            @update-value="
              (input) => {
                searchFromDate = input
                fileUrl = ''
                pdfComponentReady = false
              }
            " />
        </div>
        <div class="col-lg-4 col-md-12">
          <m-text-input
            id="to-date"
            label="To"
            type="date"
            :value="searchToDate"
            :alternative="false"
            :group="false"
            name="To Date"
            @update-value="
              (input) => {
                searchToDate = input
                fileUrl = ''
                pdfComponentReady = false
              }
            " />
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-12 col-sm-12">
        <b-button variant="success" size="sm" class="mr-2" :disabled="disableButton" @click="getGrossProfitOnSalesDetail()">Gross Profit on Sales</b-button>
        <b-button variant="success" size="sm" class="mr-2" :disabled="disableButton" @click="getNewClientDetail()">New Customer</b-button>
        <b-button variant="success" size="sm" class="mr-2" :disabled="disableButton" @click="getNewRentalDetail()">New Rental Depot</b-button>
        <b-button variant="info" size="sm" class="mr-2" @click="clearReport()">Clear</b-button>
        </div>
        
      </div>
    </div>

    <pdf-viewer :file-url="fileUrl" :file-name="fileName" @pdf-ready="watchPdfComponent($event)" />
  </div>
  
</template>

<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"
  import { baseDomain } from "@/constants/common"
  import { fiscalYear } from "@/constants/fiscal_year"
  import PdfViewer from "@/components/PdfViewer.vue"

  export default {
    name: "CommissionReports",
    components: {
      PdfViewer,
    },

    data() {
      return {
        fiscalYear: fiscalYear,
        baseDomain: baseDomain,
        fileUrl: "",
        pdfComponentReady: false,
        salesRepList: [],
        searchSalesRep: null,
        searchFromDate: null,
        searchToDate: null,
        fileName: "",
        responseData: null,
      }
    },

    computed: {
      ...mapGetters("common", ["showLoader"]),
      ...mapGetters("auth", ["user", "roles", "permissions"]),

      disableButton() {
        return !this.searchSalesRep || !this.searchFromDate || !this.searchToDate
      }
    },

    created() {},

    async mounted() {
      document.title = "Commission Reports"
      await django_session.get("erp/user/list-sales-rep/", {
        params: {
          page_size: 0
        }
      }).then((response) => {
        this.salesRepList = response.data;
      });
      if (this.roles.includes("SLS_REP")) {
        this.searchSalesRep = this.user.user_id
      }
    },

    methods: {
      watchPdfComponent(event) {
        console.log("pdfComponentReady", event)
        this.pdfComponentReady = event
      },

      async getGrossProfitOnSalesDetail() {
        this.fileUrl = baseDomain + "erp/finance/commission/gross-profit-detail/?salesRep=" + this.searchSalesRep + "&fromDate=" + this.searchFromDate + "&toDate=" + this.searchToDate
        this.fileName = "Gross Profit on Sales Detail"
      },

      getNewClientDetail() {
        this.fileUrl = baseDomain + "erp/finance/commission/new-client-detail/?salesRep=" + this.searchSalesRep + "&fromDate=" + this.searchFromDate + "&toDate=" + this.searchToDate
        this.fileName = "New Client Commission Detail"
      },

      getNewRentalDetail() {
        this.fileUrl = baseDomain + "erp/finance/commission/new-rental-detail/?salesRep=" + this.searchSalesRep + "&fromDate=" + this.searchFromDate + "&toDate=" + this.searchToDate
        this.fileName = "New Rental Detail"
      },

      clearReport() {
        this.searchSalesRep = null
        this.searchFromDate = null
        this.searchToDate = null
        this.pdfComponentReady = false
        this.fileUrl = ""
        if (this.roles.includes("SLS_REP")) {
          this.searchSalesRep = this.user.user_id
        }
      }
    }
  }
</script>